<template>
  <main>
    <package-header title="Fee Details" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">

      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link to="/pages/submit-package">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return</span>
              <span class="d-sm-none">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">

        <!-- Data entry form -->
        <div class="col-md-8">
          <p class="lead">Help text for fee details</p>

          <div class="card mt-4">
            <div class="card-header bg-light">
              <h3>A1 Release</h3>
              <small>Type: Release Charge, Lien, Interest, Notation by Execution</small>
            </div>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <h5 class="mb-1">Application 1</h5>
                <div class="row">
                  <div class="col-4 col-lg-5">Product Fee</div>
                  <div class="col-4 col-lg-2 text-right">$28.63</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$1.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
              <li class="list-group-item">
                <h5 class="mb-1">Application 2</h5>
                <div class="row">
                  <div class="col-4 col-lg-5">Product Fee</div>
                  <div class="col-4 col-lg-2 text-right">$28.63</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$1.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-4 col-lg-5">Juricert Fee</div>
                  <div class="col-4 col-lg-2 text-right">$2.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$0.00</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="card mt-5">
            <div class="card-header bg-light">
              <h3>A2 Release</h3>
              <small>Type: Release Charge, Lien, Interest, Notation by Execution</small>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <h5 class="mb-1">Application 1</h5>
                <div class="row">
                  <div class="col-4 col-lg-5">Product Fee</div>
                  <div class="col-4 col-lg-2 text-right">$28.63</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$1.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
              <li class="list-group-item">
                <h5 class="mb-1">Application 2</h5>
                <div class="row">
                  <div class="col-4 col-lg-5">Product Fee</div>
                  <div class="col-4 col-lg-2 text-right">$28.63</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$1.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-4 col-lg-5">Juricert Fee</div>
                  <div class="col-4 col-lg-2 text-right">$2.50</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-5">Service Charge</div>
                  <div class="col-4 col-lg-2 text-right">$0.00</div>
                  <div class="col-4 col-lg-2">GST $0.00</div>
                </div>
              </li>
            </ul>
          </div>


        </div>

        <!-- Fee Summary -->
        <div class="col-md-4">
          <div class="card bg-medium border-0 sticky-top">
            <div class="card-body">
              <h3 class="card-title">Fee Summary</h3>

              <dl class="mt-2">
                <dt>File Reference</dt>
                <dd>12764 Smith</dd>
              </dl>

              <p><strong>3 Items</strong></p>

              <div class="d-flex justify-content-between">
                <span>Product Fee:</span>
                <span>$59.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Service Charge:</span>
                <span>$3.00</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <span>Subtotal:</span>
                <span>$62.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>GST:</span>
                <span>$0.29</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <strong>Total</strong>
                <strong>$63.05</strong>
              </div>

              <dl class="mt-2">
                <dt>GST Registration Number</dt>
                <dd>81630 4414 RT0001</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../components/PackageHeader.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      preSearch: false,
      postSearch: false,
      tableItems: [
        {
          a:'1',
          b:'015-671-569 S/1592///A//8',
          c:'KT119399 NE Registered Sm*, P*',
          d:'$6.00',
        },
        {
          a:'2',
          b:'015-671-570 S/1592///A//9',
          c:'KT119372 NE Registered Ar*, J*',
          d:'',
        },
        {
          a:'3',
          b:'015-671-571 S/1592///A//10',
          c:'KT119348 NE Registered Da*, S*',
          d:'',
        },
        {
          a:'4',
          b:'015-671-572 S/1592///A//11',
          c:'KT119321 NE Registered Di*, S*',
          d:'',
        }
      ]
    }
  }
}
</script>

